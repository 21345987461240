<template>
  <div href="javascript:;" @click="$emit('clickItem', data)" class="sign-purchase-detail-item" :class="{'sign-purchase-detail-item-a': active}">
    {{label}}
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, defualt: () => null },
    active: { type: Boolean, defualt: false }
  },
  computed: {
    label () {
      let text = ''
      if (this.data) {
        text += 'BFSQ' + this.data.id + ' '
        text += this.data.assets.code + ' '
        text += this.data.assets.name + ' '
      }
      return text
    }
  }
}
</script>

<style lang="less" scoped>
.sign-purchase-detail-item {
  background: #e8eaec;
  padding: 0 10px;
  color: #515a6e;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.sign-purchase-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
</style>