<template>
  <div class="purchase-method-detail-file-info">
    <files
      v-if="fileFuns.get"
      :file-data-ids="data.fileIds"
      @addFile="addFile"
      :funs="fileFuns"
      @delFile="delFile"
    />
  </div>
</template>

<script>
import {
  assetsScrapApplyRequest
} from '@/api'
import Files from '@/components/base/filev2'

export default {
  components: {
    Files
  },
  props: {
    data: { type: Object, defualt: () => null },
    fun: { type: Object, defualt: () => null }
  },
  computed: {
    fileFuns () {
      return this.fun || {
        get: this.$authFunsProxy.getDataFile,
        upload: this.$authFunsProxy.updateDataFile,
        del: this.$authFunsProxy.delDataFile
      }
    }
  },
  methods: {
    async addFile (data) {
      if (!this.data.fileIds.includes(data.id)) {
        this.data.fileIds.push(data.id)
        assetsScrapApplyRequest.files(this.data.id, {
          fileIds: this.data.fileIds.join(',')
        })
      }
    },
    async delFile (data) {
      const index = this.data.fileIds.findIndex(v => data.id === v)
      if (index > -1) {
        this.data.fileIds.splice(index, 1)
        assetsScrapApplyRequest.files(this.data.id, {
          fileIds: this.data.fileIds.join(',')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-file-info {
  
}
</style>